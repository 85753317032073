<template>
  <div class="bg-primary rounded-lg z-10">
    <div class="flex flex-col lg:flex-row divide-y-1 lg:divide-x-1 lg:divide-y-0 pb-7 sm:py-8 px-7 lg:px-8">
      <div class="w-full text-center lg:text-right lg:pr-8 pb-7 lg:pb-0">
        <h2 class="text-xl sm:text-4xl font-bold tracking-tight text-white">For employers</h2>
        <p class="mt-6 sm:text-lg leading-8 text-indigo-200">
          Transform your hiring process with {{ $config.public.appName }}. Post jobs quickly and instantly reach the best-suited candidates
          using advanced filters to invite top talent from relevant areas. Assess candidates beyond resumes through video bios that showcase
          personality, enthusiasm, and language skills. Engage directly via chat and efficiently manage your hiring pipeline with verified
          profiles to ensure the highest quality talent.
        </p>
        <div class="flex items-center justify-center lg:justify-end mt-10">
          <OutlineButton @click="navigateTo(ROUTES.employers)" large class="text-primary">Claim free job credit</OutlineButton>
        </div>
      </div>
      <div class="w-full text-center lg:text-left lg:pl-8">
        <h2 class="text-xl sm:text-4xl font-bold tracking-tight text-white">Find a job</h2>
        <p class="mt-6 sm:text-lg leading-8 text-indigo-200">
          Elevate your job search with {{ $config.public.appName }} — the mobile app that goes wherever you do. Create your profile swiftly
          with AI assistance and stand out by showcasing your personality through video bios. Be the first to apply with instant
          notifications. Chat directly with potential employers and stay updated throughout the recruitment process with activity
          notifications. Download {{ $config.public.appName }} today and connect with opportunities that match your ambitions.
        </p>
        <div class="flex items-center justify-center lg:justify-start mt-10">
          <OutlineButton @click="navigateTo(ROUTES.candidates)" large class="text-primary">Download the app</OutlineButton>
        </div>
      </div>
    </div>
  </div>
</template>
